import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Direction.module.css';

const Direction: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section id="direction" className={classes.direction}>
      <h2>{t('Directions.title')}</h2>
      <div className={classes.directionContainer}>
        <div className={classes.contactInfo}>
          <p>
            <strong>{t('Phone')}:</strong>{' '}
            <a href="tel:030 74685376">030 74685376</a>
          </p>
          <p>
            <strong>Email:</strong>{' '}
            <a href="mailto:info@samphaothong.de">info@samphaothong.de</a>
          </p>
          <p>
            <strong>{t('Address')}:</strong> Ruhlebener Str. 12, 13597 Berlin
          </p>
          <p>{t('Directions.directionDescription')}</p>
        </div>
        <div className={classes.mapWrapper}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d606.825542858496!2d13.209765969679399!3d52.52796681414743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a857370103afe3%3A0xa48df00e8daaa6f3!2sSam%20Phao%20Thong!5e0!3m2!1sen!2sde!4v1722602971529!5m2!1sen!2sde"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            loading="lazy"
            title="Location"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Direction;
