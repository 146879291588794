import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import classes from './CookieSettings.module.css';

const CookieSettings: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation();
  const [reCaptchaEnabled, setReCaptchaEnabled] = useState(false);

  useEffect(() => {
    const cookiesAccepted = Cookies.get('cookiesAccepted');
    setReCaptchaEnabled(cookiesAccepted === 'true');
  }, []);

  const handleSave = () => {
    Cookies.set('cookiesAccepted', String(reCaptchaEnabled), { expires: 365 });
    onClose();
    window.location.reload();
  };

  return (
    <div className={classes.modalBackdrop}>
      <div className={classes.cookieSettings}>
        <div className={classes.settingsContent}>
          <h2>{t('CookieSettings.title')}</h2>
          <p>{t('CookieSettings.explanation')}</p>
          <div className={classes.option}>
            <label>
              <input type="checkbox" checked disabled />
              {t('CookieSettings.googleAnalytics')}
            </label>
          </div>
          <div className={classes.option}>
            <label>
              <input
                type="checkbox"
                checked={reCaptchaEnabled}
                onChange={(e) => setReCaptchaEnabled(e.target.checked)}
              />
              {t('CookieSettings.reCaptcha')}
            </label>
          </div>
          <div className={classes.buttonContainer}>
            <button onClick={handleSave}>{t('CookieSettings.save')}</button>
            <button onClick={onClose}>{t('CookieSettings.cancel')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieSettings;
