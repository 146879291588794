import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faChevronDown,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faChevronDown,
  faFacebook,
  faInstagram,
  faTwitter,
  faCheckCircle,
  faXmarkCircle
);
