import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Imprint.module.css';

const Imprint: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.imprint}>
      <h1>{t('Imprint.title')}</h1>
      <h2>{t('Imprint.operator.title')}</h2>
      <p>{t('Imprint.operator.name')}</p>
      <p>{t('Imprint.operator.address')}</p>
      <p>{t('Imprint.operator.city')}</p>
      <h2>{t('Imprint.contact.title')}</h2>
      <p>{t('Imprint.contact.phone')}</p>
      <p>{t('Imprint.contact.email')}</p>
      <h2>{t('Imprint.euDisputeResolution.title')}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: t('Imprint.euDisputeResolution.content'),
        }}
      />
      <h2>{t('Imprint.consumerDisputeResolution.title')}</h2>
      <p>{t('Imprint.consumerDisputeResolution.content')}</p>
    </div>
  );
};

export default Imprint;
