import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './Accordion.module.css';

interface AccordionProps {
  question: string;
  answer: string;
}

const Accordion: React.FC<AccordionProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.accordion}>
      <div className={classes.question} onClick={toggleAccordion}>
        <h3>{question}</h3>
        <FontAwesomeIcon
          icon="chevron-down"
          className={`${classes.icon} ${isOpen ? classes.rotate : ''}`}
        />
      </div>
      <div className={`${classes.answer} ${isOpen ? classes.open : ''}`}>
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default Accordion;
