import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './CookiePolicy.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CookiePolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.cookiePolicy}>
      <h1>{t('CookiePolicy.title')}</h1>
      <p>{t('CookiePolicy.intro')}</p>
      <h2>{t('CookiePolicy.typesOfCookies.title')}</h2>

      <table className={classes.cookieTable}>
        <thead>
          <tr>
            <th>{t('CookiePolicy.table.cookieType')}</th>
            <th>{t('CookiePolicy.table.description')}</th>
            <th>{t('CookiePolicy.table.used')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('CookiePolicy.typesOfCookies.essentialCookies.title')}</td>
            <td>{t('CookiePolicy.typesOfCookies.essentialCookies.content')}</td>
            <td style={{ textAlign: 'center', fontSize: '1.5rem' }}>
              <FontAwesomeIcon icon="circle-check" />
            </td>
          </tr>
          <tr>
            <td>{t('CookiePolicy.typesOfCookies.functionalCookies.title')}</td>
            <td>
              {t('CookiePolicy.typesOfCookies.functionalCookies.content')}
            </td>
            <td style={{ textAlign: 'center', fontSize: '1.5rem' }}>
              <FontAwesomeIcon icon="circle-check" />
            </td>
          </tr>
          <tr>
            <td>{t('CookiePolicy.typesOfCookies.analyticsCookies.title')}</td>
            <td>{t('CookiePolicy.typesOfCookies.analyticsCookies.content')}</td>
            <td style={{ textAlign: 'center', fontSize: '1.5rem' }}>
              <FontAwesomeIcon icon="circle-xmark" />
            </td>
          </tr>
          <tr>
            <td>{t('CookiePolicy.typesOfCookies.marketingCookies.title')}</td>
            <td>{t('CookiePolicy.typesOfCookies.marketingCookies.content')}</td>
            <td style={{ textAlign: 'center', fontSize: '1.5rem' }}>
              <FontAwesomeIcon icon="circle-xmark" />
            </td>
          </tr>
        </tbody>
      </table>

      <h2>{t('CookiePolicy.userConsent.title')}</h2>
      <p>{t('CookiePolicy.userConsent.content')}</p>
      <h2>{t('CookiePolicy.dataRetention.title')}</h2>
      <p>{t('CookiePolicy.dataRetention.content')}</p>
      <h2>{t('CookiePolicy.contactInfo.title')}</h2>
      <p>{t('CookiePolicy.contactInfo.content')}</p>
    </div>
  );
};

export default CookiePolicy;
