import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../Accordion/Accordion';
import classes from './FAQ.module.css';
import { motion } from 'framer-motion';

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('general');

  const renderQuestions = (category: string) => {
    switch (category) {
      case 'general':
        return (
          <>
            <Accordion
              question={t('FAQs.questions.q1')}
              answer={t('FAQs.questions.a1')}
            />
            <Accordion
              question={t('FAQs.questions.q2')}
              answer={t('FAQs.questions.a2')}
            />
            <Accordion
              question={t('FAQs.questions.q3')}
              answer={t('FAQs.questions.a3')}
            />
          </>
        );
      case 'sessions':
        return (
          <>
            <Accordion
              question={t('FAQs.questions.q4')}
              answer={t('FAQs.questions.a4')}
            />
            <Accordion
              question={t('FAQs.questions.q5')}
              answer={t('FAQs.questions.a5')}
            />
            <Accordion
              question={t('FAQs.questions.q6')}
              answer={t('FAQs.questions.a6')}
            />
            <Accordion
              question={t('FAQs.questions.q12')}
              answer={t('FAQs.questions.a12')}
            />
            <Accordion
              question={t('FAQs.questions.q7')}
              answer={t('FAQs.questions.a7')}
            />
            <Accordion
              question={t('FAQs.questions.q8')}
              answer={t('FAQs.questions.a8')}
            />
            <Accordion
              question={t('FAQs.questions.q9')}
              answer={t('FAQs.questions.a9')}
            />
          </>
        );
      case 'payments':
        return (
          <>
            <Accordion
              question={t('FAQs.questions.q11')}
              answer={t('FAQs.questions.a11')}
            />
            <Accordion
              question={t('FAQs.questions.q13')}
              answer={t('FAQs.questions.a13')}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <section id="faq" className={classes.faq}>
      <h2>{t('FAQs.headline')}</h2>
      <div className={classes.tabs}>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className={activeTab === 'general' ? classes.active : ''}
          onClick={() => setActiveTab('general')}
        >
          {t('FAQs.tabs.general')}
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className={activeTab === 'sessions' ? classes.active : ''}
          onClick={() => setActiveTab('sessions')}
        >
          {t('FAQs.tabs.sessions')}
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className={activeTab === 'payments' ? classes.active : ''}
          onClick={() => setActiveTab('payments')}
        >
          {t('FAQs.tabs.payments')}
        </motion.button>
      </div>
      {renderQuestions(activeTab)}
    </section>
  );
};

export default FAQ;
