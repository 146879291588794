import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Services.module.css';

const Services: React.FC = () => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const services = [
    {
      name: t('Services.service1.name'),
      duration: t('Services.service1.duration'),
      price: t('Services.service1.price'),
      description: t('Services.service1.description'),
    },
    {
      name: t('Services.service2.name'),
      duration: t('Services.service2.duration'),
      price: t('Services.service2.price'),
      description: t('Services.service2.description'),
    },
    {
      name: t('Services.service3.name'),
      duration: t('Services.service3.duration'),
      price: t('Services.service3.price'),
      description: t('Services.service3.description'),
    },
    {
      name: t('Services.service4.name'),
      duration: t('Services.service4.duration'),
      price: t('Services.service4.price'),
      description: t('Services.service4.description'),
    },
    {
      name: t('Services.service5.name'),
      duration: t('Services.service5.duration'),
      price: t('Services.service5.price'),
      description: t('Services.service5.description'),
    },
  ];

  const handleRowClick = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section id="services" className={classes.services}>
      <h2>{t('Services.title')}</h2>
      <div className={classes.tableWrapper}>
        <table className={classes.servicesTable}>
          <thead>
            <tr>
              <th>{t('Services.service')}</th>
              <th>{t('Services.duration')}</th>
              <th>{t('Services.price')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {services.map((service, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => handleRowClick(index)}
                  className={`${classes.row} ${expandedIndex === index ? classes.expanded : ''}`}
                >
                  <td>{service.name}</td>
                  <td>{service.duration}</td>
                  <td>{service.price}</td>
                  <td className={classes.iconCell}>
                    {expandedIndex === index ? (
                      <span className={classes.icon}>-</span>
                    ) : (
                      <span className={classes.icon}>+</span>
                    )}
                  </td>
                </tr>
                {expandedIndex === index && (
                  <tr className={classes.expandedRow}>
                    <td colSpan={4}>{service.description}</td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Services;
