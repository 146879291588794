import React from 'react';
import Slider from 'react-slick';
import classes from './Gallery.module.css';
import interior1 from '../../assets/images/gallery/interior-1.webp';
import interior2 from '../../assets/images/gallery/interior-2.webp';
import interior3 from '../../assets/images/gallery/interior-3.webp';
import interior4 from '../../assets/images/gallery/interior-4.webp';
import { useTranslation } from 'react-i18next';

const Gallery: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  const { t } = useTranslation();

  return (
    <section id="gallery" className={classes.gallery}>
      <h2>{t('Gallery.title')}</h2>
      <Slider {...settings} className={classes.slider}>
        <div className={classes.imageContainer}>
          <img src={interior1} alt="Interior 1" className={classes.image} />
        </div>
        <div className={classes.imageContainer}>
          <img src={interior2} alt="Interior 2" className={classes.image} />
        </div>
        <div className={classes.imageContainer}>
          <img src={interior3} alt="Interior 3" className={classes.image} />
        </div>
        <div className={classes.imageContainer}>
          <img src={interior4} alt="Interior 4" className={classes.image} />
        </div>
      </Slider>
    </section>
  );
};

export default Gallery;
