import React from 'react';
import classes from './Popup.module.css';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

interface PopupProps {
  message: string;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ message, onClose }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.popup}>
      <div className={classes.popupContent}>
        <p>{message}</p>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={onClose}
        >
          {t('Close')}
        </motion.button>
      </div>
    </div>
  );
};

export default Popup;
