import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { scrollToSection } from './scrollToSection';

const ScrollHandler: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      scrollToSection(hash);
    } else if (location.state && location.state.scrollTo) {
      scrollToSection(location.state.scrollTo);
      // Clear the state after scrolling to prevent scrolling on reload
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  return null;
};

export default ScrollHandler;
