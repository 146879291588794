import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './PrivacyPolicy.module.css';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.privacyPolicy}>
      <h1>{t('PrivacyPolicy.title')}</h1>
      <p>{t('PrivacyPolicy.intro')}</p>
      <h2>{t('PrivacyPolicy.dataCollection.title')}</h2>
      <p>{t('PrivacyPolicy.dataCollection.content')}</p>
      <h2>{t('PrivacyPolicy.dataUsage.title')}</h2>
      <p>{t('PrivacyPolicy.dataUsage.content')}</p>
      <h2>{t('PrivacyPolicy.dataSharing.title')}</h2>
      <p>{t('PrivacyPolicy.dataSharing.content')}</p>
      <h2>{t('PrivacyPolicy.dataSecurity.title')}</h2>
      <p>{t('PrivacyPolicy.dataSecurity.content')}</p>
      <h2>{t('PrivacyPolicy.userRights.title')}</h2>
      <p>{t('PrivacyPolicy.userRights.content')}</p>
      <h2>{t('PrivacyPolicy.dataRetention.title')}</h2>
      <p>{t('PrivacyPolicy.dataRetention.content')}</p>
      <h2>{t('PrivacyPolicy.contactInfo.title')}</h2>
      <p>{t('PrivacyPolicy.contactInfo.content')}</p>
    </div>
  );
};

export default PrivacyPolicy;
