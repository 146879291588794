import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './TermsOfUse.module.css';

const TermsOfUse: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.termsOfUse}>
      <h1>{t('TermsOfUse.title')}</h1>
      <p>{t('TermsOfUse.intro')}</p>
      <h2>{t('TermsOfUse.intellectualProperty.title')}</h2>
      <p>{t('TermsOfUse.intellectualProperty.content')}</p>
      <h2>{t('TermsOfUse.userResponsibilities.title')}</h2>
      <p>{t('TermsOfUse.userResponsibilities.content')}</p>
      <h2>{t('TermsOfUse.disputeResolution.title')}</h2>
      <p>{t('TermsOfUse.disputeResolution.content')}</p>
      <h2>{t('TermsOfUse.contactInfo.title')}</h2>
      <p>{t('TermsOfUse.contactInfo.content')}</p>
    </div>
  );
};

export default TermsOfUse;
