import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import classes from './Contact.module.css';
import axios from 'axios';
import Popup from '../Popup/Popup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { motion } from 'framer-motion';

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    setCookiesAccepted(Cookies.get('cookiesAccepted') === 'true');
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (cookiesAccepted && executeRecaptcha) {
      const recaptchaToken = await executeRecaptcha('contact_form');
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/send_contact_form`,
          {
            ...formData,
            recaptcha_token: recaptchaToken,
          }
        );
        if (response.status === 200) {
          setPopupMessage(t('Contact.success'));
        }
      } catch (error) {
        setPopupMessage(t('Contact.error'));
      } finally {
        setLoading(false);
      }
    } else if (!cookiesAccepted) {
      setPopupMessage(t('Contact.cookieRequired'));
      setLoading(false);
    } else {
      setPopupMessage(t('Contact.reCaptchaError'));
      setLoading(false);
    }
  };

  const closePopup = () => {
    setPopupMessage('');
  };

  return (
    <section id="contact" className={classes.contact}>
      <h2>{t('Contact.title')}</h2>
      <div className={classes.contactContainer}>
        <div className={classes.contactText}>
          <p>{t('Contact.contactText')}</p>
        </div>
        <form className={classes.contactForm} onSubmit={handleSubmit}>
          <label htmlFor="name">{t('Contact.form.name')}:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label htmlFor="email">{t('Contact.form.email')}:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label htmlFor="message">{t('Contact.form.message')}:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>

          <div className={classes.checkboxContainer}>
            <input type="checkbox" id="privacy" name="privacy" required />
            <label htmlFor="privacy">{t('Contact.privacyStatement')}</label>
          </div>

          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            disabled={loading}
          >
            {loading ? t('Contact.sending') : t('Contact.form.submit')}
          </motion.button>
        </form>
        {popupMessage && <Popup message={popupMessage} onClose={closePopup} />}
      </div>
    </section>
  );
};

export default Contact;
