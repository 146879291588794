import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import classes from './CookiePopup.module.css';
import CookieSettings from './CookieSettings';

const CookiePopup: React.FC = () => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const cookiesAccepted = Cookies.get('cookiesAccepted');
    if (!cookiesAccepted) {
      Cookies.set('__Secure-ENID', 'your-value', {
        domain: '.google.com',
        secure: true,
        sameSite: 'Lax',
        expires: 365,
      });
      setShowPopup(true);
    }
  }, []);

  const handleAccept = () => {
    setShowPopup(false);
    Cookies.set('cookiesAccepted', 'true', { expires: 365 });

    // Reload the page to apply the changes
    window.location.reload();
  };

  const handleSettings = () => {
    setShowSettings(true);
  };

  if (showSettings) {
    return <CookieSettings onClose={() => setShowSettings(false)} />;
  }

  if (!showPopup) return null;

  return (
    <div className={classes.cookiePopup}>
      <div className={classes.cookieContent}>
        <p>{t('CookiePopup.message')}</p>
        <p>{t('CookiePopup.details')}</p>
        <div className={classes.buttonContainer}>
          <button onClick={handleAccept}>{t('CookiePopup.accept')}</button>
          <button onClick={handleSettings}>{t('CookiePopup.settings')}</button>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;
