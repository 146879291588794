import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './About.module.css';

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className={classes.about}>
      <h2>{t('About Us')}</h2>
      <div className={classes.aboutContent}>
        <div className={classes.description}>
          <p>{t('about.description')}</p>
        </div>
        <div className={classes.openingHours}>
          <div className={classes.openingHoursInner}>
            <h3>{t('Opening Hours')}</h3>
            <ul>
              <li>{t('Monday')}: 10:00 - 20:00</li>
              <li>{t('Tuesday')}: 10:00 - 20:00</li>
              <li>{t('Wednesday')}: 10:00 - 20:00</li>
              <li>{t('Thursday')}: 10:00 - 20:00</li>
              <li>{t('Friday')}: 10:00 - 20:00</li>
              <li>{t('Saturday')}: 10:00 - 20:00</li>
              <li>
                {t('Sunday')}: {t('Closed')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
